import * as React from 'react';
import { Link } from 'gatsby';

// Links and headers for right-side index content. Probably should move to mdx if possible

const sideTopics = () => {
  return (
    <div>
      <h2>常见问题</h2>
      <ul>
        <li>
          <Link to="/shengming-de-yiyi-hezai">生命的意义何在?</Link>
        </li>
        <li>
          <Link to="/ni-sihou-hui-fasheng-shenme">你死后会发生什么?</Link>
        </li>
        <li>
          <Link to="/zhansheng-jupa">战胜惧怕</Link>
        </li>
      </ul>
      <h2>当前搜索</h2>
      <ul>
        <li>
          <Link to="/shiqu-xin-ai-de-ren">失去心爱的人</Link>
        </li>
        <li>
          <Link to="/ruhe-kaishi-xinshenghuo">如何开始新生活</Link>
        </li>
        <li>
          <Link to="/tingzhi-baoli">停止暴力</Link>
        </li>
      </ul>
      <p>
        你还在查找别的内容吗？
        <Link to="/huati">请用片刻时间来浏览更多的话题和问题</Link>
        。你也可以与我们聊聊你心里所思虑的任何事
      </p>
    </div>
  );
};

export default sideTopics;
