import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout';
import { SEO } from '../components/seo';
import SideTopics from '../components/sideTopics';
import {
  bodyRight,
  bodyLeft,
  indexBody,
  banner,
  dots,
  imageText,
} from '../components/layout.module.css';
import { StaticImage } from 'gatsby-plugin-image';
import dotImage from '../images/dots-light.png';

// Template for the index page, used because the content needs to float when on mobile

function IndexPage({ pageContext }) {
  const page = pageContext;
  const meta = {
    title: page.frontmatter.title,
    description: page.frontmatter.description,
  };
  return (
    <Layout meta={meta} pageTitle={meta.title}>
      <StaticImage
        className={banner}
        src="../images/home.jpg"
        alt="banner"
        loading="eager"
        quality={100}
        objectFit="cover"
        style={{ position: 'absolute', width: '100%' }}
      />
      <p className={imageText}>倾听 关怀 为您祷告</p>
      {/* Body div which holds left and right content, managed by flex so it works on mobile */}
      <div className={indexBody}>
        <div className={bodyLeft}>
          <MDXRenderer>{page.body}</MDXRenderer>
          <img className={dots} src={dotImage} alt="dots" />
        </div>
        <div className={bodyRight}>
          <SideTopics />
        </div>
      </div>
    </Layout>
  );
}

export default IndexPage;

export const Head = (pageContext) => <SEO pageContext={pageContext} />;
